import { Box, IconButton, useTheme, Typography  } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
//import HomeIcon from "@mui/icons-material/Home";
//import InfoIcon from "@mui/icons-material/Info";
//import ContactMailIcon from "@mui/icons-material/ContactMail";
//import StoreIcon from "@mui/icons-material/Store";
//import GetAppIcon from "@mui/icons-material/GetApp";
import logo from '../../image/logo.png'

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        borderRadius="3px"
      >
       <img src={logo} alt="Company Logo" style={{ height: "55px", width: "150px" }} />
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton component="a" href="/">
          {/* <HomeIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>Accueil</Typography>
        </IconButton>
        <IconButton component="a" href="/service">
          {/*<InfoIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>Nos services</Typography>
        </IconButton>
        <IconButton component="a" href="/about">
          {/*<InfoIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>À propos</Typography>
        </IconButton>
        <IconButton component="a" href="/contact">
          {/*<ContactMailIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>Nous contacter</Typography>
        </IconButton>
        <IconButton component="a" href="/privacyPolicy">
          {/*<StoreIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>Politique & Confidentialité</Typography>
        </IconButton>
        <IconButton component="a" href="/term">
          {/*<GetAppIcon />*/}
          <Typography variant="body1" sx={{ ml: 1 }}>Terme & usage</Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
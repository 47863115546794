import React from "react";
import { Box, Typography, Link, Container, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from '@mui/icons-material/Twitter';
//import YouTubeIcon from '@mui/icons-material/YouTube';
//import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from "@mui/icons-material/Instagram";
import GooglePlay from "../../image/playstore.png";
import AppStore from "../../image/apple.png";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "blue", color: "#fff", py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Entreprise
            </Typography>
            <Link href="/about" color="inherit" display="block" gutterBottom>
              À propos
            </Link>
            <Link href="/service" color="inherit" display="block" gutterBottom>
              Nos services
            </Link>
            {/*<Link href="#" color="inherit" display="block" gutterBottom>Espace presse</Link>*/}
            {/*<Link href="#" color="inherit" display="block" gutterBottom>Investisseurs</Link>*/}
            <Link href="#" color="inherit" display="block" gutterBottom>
              Blog
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Offres d'emploi
            </Link>
            {/*<Link href="#" color="inherit" display="block" gutterBottom>AI</Link>*/}
            <Link href="#" color="inherit" display="block" gutterBottom>
              Code promotionnel
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Produits
            </Typography>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Déplacez-vous avec Djonan
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Livrez
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Commandez un repas
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Devenir Chauffeur
            </Link>
            {/*<Link href="#" color="inherit" display="block" gutterBottom>Uber for Business</Link>
            <Link href="#" color="inherit" display="block" gutterBottom>Uber Freight</Link>*/}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Citoyens du monde
            </Typography>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Sécurité
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Diversité et intégration
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Développement durable
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Déplacements
            </Typography>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Réservez
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Aéroports
            </Link>
            <Link href="#" color="inherit" display="block" gutterBottom>
              Villes
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Link href="#" color="inherit" sx={{ mr: 2 }}>
              Français (France)
            </Link>
            <Link href="#" color="inherit">
              Burkina Faso
            </Link>
          </Box>
          <Box>
            <Link href="/privacyPolicy" color="inherit" sx={{ mr: 2 }}>
              Confidentialité
            </Link>
            <Link href="#" color="inherit" sx={{ mr: 2 }}>
              Accessibilité
            </Link>
            <Link href="/term" color="inherit">
              Conditions
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Link href="https://web.facebook.com/profile.php?id=100088479655549" color="inherit" sx={{ mx: 1 }}>
            <FacebookIcon />
          </Link>
          <Link href="#" color="inherit" sx={{ mx: 1 }}>
            <InstagramIcon />
          </Link>
          {/*<Link href="#" color="inherit" sx={{ mx: 1 }}><TwitterIcon /></Link>
          <Link href="#" color="inherit" sx={{ mx: 1 }}><YouTubeIcon /></Link>
          <Link href="#" color="inherit" sx={{ mx: 1 }}><LinkedInIcon /></Link>*/}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
            <Link href="#" sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={GooglePlay}
                alt="Google Play"
                style={{ width: 20, marginRight: 8 }}
              />
              <Typography variant="body2" color="white">
                Google Play
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link href="#" sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={AppStore}
                alt="App Store"
                style={{ width: 20, marginRight: 8 }}
              />
              <Typography variant="body2" color="white">
                App Store
              </Typography>
            </Link>
          </Box>
        </Box>

        <Typography
          variant="body2"
          color="inherit"
          align="center"
          sx={{ mt: 4 }}
        >
          © 2024 Djonan Technologies Inc.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

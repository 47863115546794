import React, {useRef} from "react";
import { Box, Button, TextField, Typography, Avatar } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import helpImage from "./../../image/livraison.jpg"; // Assurez-vous de remplacer ce chemin par le chemin réel de votre image d'aide
import attachIcon from "../../image/livraison.jpg"; // Assurez-vous de remplacer ce chemin par le chemin réel de votre icône d'attachement
import womanAndChild from "../../image/livraison.jpg";
import emailjs from '@emailjs/browser';

const Form = () => {
  const form = useRef();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_dqv6dmk', 'template_b9ojewp', form.current, {
        publicKey: 'rMr1S_vGXPPY--5fP',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          console.log(e)
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" mb={4}>
        <Avatar
          src={helpImage}
          alt="Help"
          sx={{ width: 80, height: 80, mr: 2 }}
        />
        <Typography variant="body1">
          Veuillez saisir les champs et appuyer sur envoyer. Un conseiller vous
          répondra sous 24H. Vous pouvez aussi contacter Djonan au 05769090 ou
          58052020.
        </Typography>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form ref={form} onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="message"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.message}
                name="message"
                error={!!touched.message && !!errors.message}
                helperText={touched.message && errors.message}
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4} // Adjust the number of rows as needed
              />

              <Box
                sx={{
                  gridColumn: "span 4",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Button variant="outlined" component="label">
                  <img
                    src={attachIcon}
                    alt="Attach"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />
                  Attacher un document
                  <input type="file" hidden />
                </Button>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={handleFormSubmit}>
                Envoyer
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
    message: yup.string().required("required"),
  address2: yup.string().required("required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  message: "",
  message: "",
};

export default Form;

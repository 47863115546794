import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Conditions générales d'utilisation
      </Typography>

      <Typography variant="body1" paragraph>
        Djonan est exploitée par ZS SOLUTIONS, une société constituée et enregistrée au Burkina Faso, dont le siège social est à Ouagadougou, quartier 1200 logements. Notre numéro d'enregistrement de la Société est : 00194947Z. Vous pouvez nous contacter à l’adresse email contact@djonan.net ou par téléphone au +226 05 76 90 90.
      </Typography>
      
      <Typography variant="body1" paragraph>
        Notre objectif est de vous permettre, à partir de notre plateforme de :
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Effectuer des commandes de repas et d'autres produits dans votre Restaurant, votre Cave, votre Pharmacie, votre Supermarché et magasin préféré et de vous faire livrer par notre équipe de livreurs." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Assurer le prélèvement et la livraison de vos colis." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Réserver un taxi pour vos déplacements." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Louer une voiture ou une moto pour vos déplacements." />
        </ListItem>
      </List>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        1. Notre Déclaration de confidentialité
      </Typography>
      <Typography variant="body1" paragraph>
        La présente politique de confidentialité énonce notre politique en ce qui concerne les informations qui peuvent être associées ou liées à une personne en particulier et/ou qui pourraient être utilisées pour identifier une personne (« Données personnelles ») et qui sont collectées auprès des utilisateurs sur les Services. Nous prenons au sérieux la confidentialité de vos données personnelles. C’est pour cette raison que nous avons créé la présente Politique de confidentialité. Pour cette raison, nous avons créé la présente Politique de confidentialité que nous vous demandons de lire car elle comprend des informations importantes concernant vos données personnelles et d’autres informations.
      </Typography>
      <Typography variant="body1" paragraph>
        Les « Données non personnelles » qui sont utilisées dans la présente Politique de confidentialité sont donc toute information non associée à une personne et/ou ne pouvant pas être utilisée pour identifier une personne. Lorsque vous interagissez avec les Services, nous pouvons également recueillir des données non personnelles. Les limites et les exigences de la présente Politique de confidentialité sur notre collecte, utilisation, communication, transfert et stockage/conservation des Données personnelles ne sont pas applicables aux Données non personnelles.
      </Typography>
      <Typography variant="body1" paragraph>
        La collecte des données personnelles recueillies par le biais de DJONAN est nécessaire au bon fonctionnement du Service et est obligatoire pour accéder aux services proposés via l’Application Mobile. Le défaut de communication des données personnelles obligatoires empêche l’accès au Service proposé par DJONAN. Ces données personnelles sont utilisées afin de fournir de meilleurs services aux utilisateurs.
      </Typography>
      <Typography variant="body1" paragraph>
        DJONAN réalise la conservation des Données Personnelles de façon à permettre le respect de leur intégrité durant le temps nécessaire à la réalisation et la fourniture du Service proposé et conformément aux durées de conservation imposées par la législation en vigueur.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        2. Qui peut utiliser notre application ?
      </Typography>
      <Typography variant="body1" paragraph>
        Notre application est destinée aux personnes de bonne moralité.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        3. Quelles informations collectons-nous à votre sujet ?
      </Typography>
      <Typography variant="body1" paragraph>
        Les informations que vous nous fournissez : Les Données personnelles que nous recueillons comprennent sans s’y limiter, vos prénoms, votre nom, et votre numéro de téléphone.
      </Typography>
      <Typography variant="body1" paragraph>
        Les informations que nous collectons automatiquement : nous collectons également certaines Données automatiquement lors de la commande d'un livreur tels que : le quartier, la ville et les données de localisation (longitude et latitude). Les Données automatiques incluent, sans s’y limiter, un identificateur unique associé à votre dispositif d’accès et/ou votre navigateur [y compris par exemple votre adresse de protocole Internet (IP)], des caractéristiques sur votre dispositif d’accès et/ou votre navigateur, des statistiques sur vos activités sur les Services, des informations sur la manière dont vous avez accédé aux Services et les Données recueillies par les cookies et d’autres technologies similaires.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        4. Comment nous utilisons vos Données personnelles ?
      </Typography>
      <Typography variant="body1" paragraph>
        Nous utilisons vos informations pour vous fournir la Plateforme, lui apporter des améliorations et pour son administration.
      </Typography>
      <Typography variant="body1" paragraph>
        Nous utiliserons vos Données personnelles pour vous permettre d’accéder à l’ensemble de nos fonctionnalités, et pour analyser votre utilisation de ces Services ou fonctionnalités.
      </Typography>
      <Typography variant="body1" paragraph>
        Nous utilisons aussi vos données, dans les cas où nous avons un intérêt légitime, pour améliorer et développer la Plateforme, lutter contre les activités criminelles et assurer la sécurité de nos utilisateurs, et ce afin d’offrir une Plateforme dynamique et efficace.
      </Typography>
      <Typography variant="body1" paragraph>
        Nous pouvons utiliser vos Données personnelles à des fins commerciales internes, y compris, sans limitation, pour nous aider à améliorer le contenu et les fonctionnalités des Services, afin de mieux comprendre nos Utilisateurs, pour améliorer les Services, afin de protéger, d’identifier ou de répondre des actes fautifs, pour faire respecter nos Conditions d’utilisation, pour gérer votre compte et pour vous fournir un service à la clientèle et généralement pour gérer les Services et nos activités.
      </Typography>
      <Typography variant="body1" paragraph>
        Si nous avons l’intention d’utiliser toute Donnée personnelle d’une manière qui n’est pas conforme à la présente Politique de confidentialité, vous serez informé d’une telle utilisation prévue avant que les Données personnelles ne soient collectées ou au moment de leur collecte, où nous obtiendrons votre consentement à la suite de cette collecte, avant ladite utilisation.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        5. Comment nous divulguons et transférons vos Données personnelles ?
      </Typography>
      <Typography variant="body1" paragraph>
        Nous ne travaillons pas dans le domaine de la vente de vos Données personnelles. Nous considérons que ces informations sont un élément vital de notre relation avec vous. Par conséquent, nous ne vendrons pas vos Données personnelles à des tierces parties, y compris à des annonceurs tiers.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        6. Comment nous stockons vos Données personnelles ?
      </Typography>
      <Typography variant="body1" paragraph>
        Nous pouvons stocker des Données personnelles, ou ces informations peuvent être stockées par des tiers à qui nous les avons transférées conformément à la présente Politique de confidentialité. Nous prenons ce que nous croyons être des mesures raisonnables pour protéger les Données personnelles recueillies via les Services contre toute perte, mauvaise utilisation, accès non autorisé, divulgation accidentelle, altération et destruction. Toutefois, aucun réseau, serveur, base de données ni transmission par Internet ou e-mail n’est jamais entièrement sécurisé(e) ni exempt(e) d’erreurs. Par conséquent, vous devez être particulièrement prudent lorsque vous décidez quels renseignements vous nous envoyez électroniquement. Veuillez garder ceci à l’esprit lors de la divulgation de vos Données personnelles.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        7. Comment vous pouvez accéder à vos Données personnelles, les mettre à jour, les corriger ou les supprimer ?
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez retrouver les informations que vous nous avez fournie à l’inscription en vous connectant et en allant sur la page Paramètres.
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez également modifier certaines de vos Données personnelles directement par le biais de votre compte. Dans certains cas, vous pouvez nous demander de corriger et mettre à jour toute Donnée personnelle incorrecte en utilisant les coordonnées de contact ci-dessous, et nous étudierons votre demande conformément aux lois en vigueur.
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez également demander une copie de l’ensemble de vos données dont nous disposons en envoyant un mail à l’adresse indiquée.
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez également demander la suppression de votre compte via la page Paramètres.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        8. Cookies
      </Typography>
      <Typography variant="body1" paragraph>
        La navigation sur DJONAN est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
      </Typography>
      <Typography variant="body1" paragraph>
        Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies : Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        9. Contacter Djonan
      </Typography>
      <Typography variant="body1" paragraph>
        Pour toutes demandes d'informations concernant les conditions générales d'utilisation, vous pouvez nous contacter via contact@djonan.net.
      </Typography>
      
    </Box>
  );
};

export default TermsAndConditions;

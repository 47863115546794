import React, { useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  IconButton,
  CardActionArea,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SendIcon from "@mui/icons-material/Send";
import header from "../../image/headerok.jpg";
import livraison from "../../image/livraison.jpg";
import drive from "../../image/drive.jpg";
import qr from "../../image/qr.jpg";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LuggageIcon from "@mui/icons-material/Luggage";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    h1: {
      fontSize: "3rem", // Increased font size
      fontWeight: "bold",
    },
    h2: {
      fontSize: "2.5rem", // Increased font size
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1.2rem", // Increased font size
    },
    body2: {
      fontSize: "1rem", // Increased font size
    },
  },
});

const Home = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAZ0XpsW0jgMDNR8QKDyrNEkUjoJnSSDpk",
    libraries: ["places"],
  });
  const originRef = useRef();
  const destiantionRef = useRef();

  if (!isLoaded) {
    return (
      <Box display="flex" alignItems="center" color="white">
        <Typography>loading</Typography>
      </Box>
    );
  }

  const handleVoirLesPrixClick = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.kardersam1146.Djonans";
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        minHeight="100vh"
        bgcolor="blue"
        color="white"
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          p={4}
          width="100%"
        >
          <Box display="flex" flexDirection="column" gap={2} maxWidth="450px">
            {" "}
            {/* Increased max width */}
            <Typography variant="h1">
              Djonan votre service de Livraison et de transport
            </Typography>
            <Typography variant="body1">
              Commandez une course en un clic et c'est partie.
            </Typography>
            <Box component="form" display="flex" flexDirection="column" gap={2}>
              <Autocomplete>
                <TextField
                  ref={originRef}
                  variant="outlined"
                  placeholder="Lieu de prise en charge"
                  InputProps={{
                    startAdornment: (
                      <LocationOnIcon position="start" color="black" />
                    ),
                    style: { backgroundColor: "white", borderRadius: "5px" },
                  }}
                  fullWidth
                  sx={{
                    input: {
                      color: "black",
                    },
                  }}
                />
              </Autocomplete>
              <Autocomplete>
                <TextField
                  ref={destiantionRef}
                  variant="outlined"
                  placeholder="Saisissez votre destination"
                  InputProps={{
                    startAdornment: <SendIcon position="start" />,
                    style: { backgroundColor: "white", borderRadius: "5px" },
                  }}
                  fullWidth
                  sx={{
                    input: {
                      color: "black",
                    },
                  }}
                />
              </Autocomplete>

              {/* New Section: Service Selection */}
              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                mt={2}
                bgcolor="white"
                p={2}
                borderRadius="8px"
              >
                <Card
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "45%",
                  }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <LocalShippingIcon fontSize="small" />
                      <Typography variant="body2">Livraison</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "45%",
                  }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <RestaurantIcon fontSize="small" />
                      <Typography variant="body2">Repas</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "45%",
                  }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <DriveEtaIcon fontSize="small" />
                      <Typography variant="body2">Drive</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "45%",
                  }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <DriveEtaIcon fontSize="small" />
                      <Typography variant="body2">Location</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "45%",
                  }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <LuggageIcon fontSize="small" />
                      <Typography variant="body2">Transport</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>

              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 2, borderRadius: "5px" }}
                onClick={handleVoirLesPrixClick}
              >
                Voir les prix
              </Button>
            </Box>
          </Box>
          <Box>
            <img
              src={header}
              alt="Illustration"
              style={{ maxHeight: "110vh", borderRadius: "10px" }} // Increased max height
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          p={4}
          bgcolor="white"
          color="black"
          width="100%"
        >
          <Box>
            <img
              src={livraison}
              alt="Illustration"
              style={{ maxHeight: "85vh", borderRadius: "10px" }} // Increased max height
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} maxWidth="450px">
            {" "}
            {/* Increased max width */}
            <Typography variant="h2" fontWeight="bold">
              Bénéficiez des services de notre société spécialisée dans le
              transport de marchandises.
            </Typography>
            <Typography variant="body1">
              Un transport express, sans rupture de charge: l'enlèvement et la
              livraison de vos plis et colis se fait directement sur place, avec
              remise en main propre, porte à porte. Les délais sont connus à
              l'avance. Une fiabilité à toute épreuve : grâce à nos experts, vos
              plis et colis ne subissent aucun risque de casse, de vol, de
              détérioration ou de perte. Une flexibilité hors pair : vous
              souhaitez envoyer en urgence un colis le weekend ou en fin de
              journée ? Contactez-nous pour que nous adaptions nos services à
              vos besoins.
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={handleVoirLesPrixClick}>
              Commencez
            </Button>
            <Typography variant="body2" onClick={handleVoirLesPrixClick}>
              Vous avez déjà un compte ? Connectez-vous
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          p={4}
          bgcolor="white"
          color="black"
          width="100%"
        >
          <Box display="flex" flexDirection="column" gap={2} maxWidth="450px">
            {" "}
            {/* Increased max width */}
            <Typography variant="h2" fontWeight="bold">
              Déplacez vous en toute sécurité avec Djonan Transport
            </Typography>
            <Typography variant="body1">
              Avec Djonan Transport, profitez de services de transport de
              qualité, que ce soit en moto ou en voiture. Nous nous engageons à
              vous offrir une expérience de déplacement sûre, rapide et
              confortable, adaptée à vos besoins quotidiens.
            </Typography>
            <Box display="flex" gap={2}>
              <Button variant="contained" color="primary" size="large" onClick={handleVoirLesPrixClick}>
                Commencez
              </Button>
              <Button variant="outlined" color="primary" size="large" onClick={handleVoirLesPrixClick}>
                Découvrez nos solutions
              </Button>
            </Box>
          </Box>
          <Box>
            <img
              src={drive}
              alt="Illustration"
              style={{ maxHeight: "85vh", borderRadius: "10px" }} // Increased max height
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          p={4}
          bgcolor="white"
          color="black"
          width="100%"
        >
          <Typography variant="h2" fontWeight="bold" align="center">
            C'est plus simple dans les applications
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            gap={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={2}
              bgcolor="white"
              color="black"
              border="1px solid #ccc"
              borderRadius="8px"
              maxWidth="400px"
            >
              <img src={qr} alt="QR Code 1" style={{ maxWidth: "150px" }} />
              <Typography variant="body1" align="center">
                Téléchargez l'application Djonan
              </Typography>
              <Typography variant="body2" align="center">
                Scannez pour télécharger
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={2}
              bgcolor="white"
              color="black"
              border="1px solid #ccc"
              borderRadius="8px"
              maxWidth="400px"
            >
              <img src={qr} alt="QR Code 2" style={{ maxWidth: "150px" }} />
              <Typography variant="body1" align="center">
                Devenez chauffeur Djonan
              </Typography>
              <Typography variant="body2" align="center">
                Scannez pour télécharger
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Home;

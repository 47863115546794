import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import stockMarch from "../../image/stockMarch.jpg"; 
import taxidrive from "../../image/taxidrive.jpg"; 
import location from "../../image/location.jpg"; 
import livraisonService from "../../image/livraisonService.jpg"; 


const ServiceScreen = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom fontSize={20} fontWeight='bold'>
        Présentation du service de transport et de livraison Djonan
      </Typography>
      <Typography variant="body1" paragraph>
        Notre entreprise propose une gamme complète de services de livraison pour répondre à tous vos besoins logistiques. Que vous ayez besoin de transporter des personnes, d'acheminer des colis ou de stocker vos marchandises, nous sommes là pour vous offrir des solutions fiables et efficaces.
      </Typography>
      
      <Divider sx={{ marginY: 4 }} />
      
      <Box>
        <Typography variant="h5" gutterBottom fontSize={20} fontWeight='bold'>
          Nos Services
        </Typography>
        
        <Box sx={{ marginTop: 4 }}>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <img src={location} alt="Location de véhicules" style={{ width: '100%' }} />
          </Box>
          <Typography variant="h6" gutterBottom fontSize={20} fontWeight='bold'>
            Service de location de véhicules
          </Typography>
          <Typography variant="body1" paragraph>
            Notre service de location de véhicules vous offre une flexibilité totale pour répondre à vos besoins spécifiques en matière de transport. Que vous ayez besoin d'une voiture de luxe pour une occasion spéciale, d'un bus pour un voyage en groupe ou d'une voiture tout-terrain pour explorer des terrains difficiles, nous avons une large sélection de véhicules adaptés à vos préférences.
            Nous comprenons que chaque client a des exigences différentes, c'est pourquoi nous proposons des options de location avec ou sans chauffeurs. Si vous préférez conduire vous-même, nous mettons à votre disposition des véhicules bien entretenus, sûrs et fiables. Vous pouvez ainsi profiter d'une liberté totale et organiser votre voyage selon vos propres horaires.
          </Typography>
        </Box>
        
        <Divider sx={{ marginY: 4 }} />
        
        <Box>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <img src={taxidrive} alt="Transport de personnes en taxi et à moto" style={{ width: '100%' }} />
          </Box>
          <Typography variant="h6" gutterBottom fontSize={20} fontWeight='bold'>
            Service de transport de personnes en taxi et à moto
          </Typography>
          <Typography variant="body1" paragraph>
            Notre service de transport de personnes en taxi et à moto est conçu pour vous offrir une mobilité facile et pratique, où que vous soyez. Que vous ayez besoin de vous rendre à une réunion, à l'aéroport ou à n'importe quelle destination, notre équipe de chauffeurs professionnels est là pour vous conduire en toute sécurité et confortablement.
            Lorsque vous réservez un taxi ou une moto avec nous, vous pouvez compter sur la ponctualité et la fiabilité de notre service. Nos chauffeurs connaissent bien la région et utilisent des itinéraires optimisés pour vous assurer d'arriver à destination dans les délais. De plus, nos engins sont régulièrement entretenus et équipés de systèmes de navigation pour garantir des trajets sans tracas.
          </Typography>
        </Box>
        
        <Divider sx={{ marginY: 4 }} />
        
        <Box>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <img src={livraisonService} style={{ width: '100%' }} />
          </Box>
          <Typography variant="h6" gutterBottom fontSize={20} fontWeight='bold'>
            Service de livraison
          </Typography>
          <Typography variant="body1" paragraph>
            Notre service de livraison est conçu pour prendre en charge vos colis et courriers, en veillant à ce qu'ils soient livrés rapidement et en toute sécurité à leur destination. Nous comprenons l'importance de livrer vos envois de manière fiable et efficace, c'est pourquoi nous mettons tout en œuvre pour vous offrir un service de livraison de haute qualité.
            Nous nous efforçons de respecter les délais convenus et de livrer vos envois dans les plus brefs délais. Que ce soit pour des envois locaux ou internationaux, nous travaillons avec des partenaires de confiance pour assurer une livraison rapide et fiable.
          </Typography>
        </Box>
        
        <Divider sx={{ marginY: 4 }} />
        
       {/* <Box>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <img src="path_to_grocery_delivery_service_image.jpg" alt="Service de livraison de courses" style={{ width: '100%' }} />
          </Box>
          <Typography variant="h6" gutterBottom fontSize={20} fontWeight='bold'>
            Service de livraison de courses
          </Typography>
          <Typography variant="body1" paragraph>
            Notre service de livraison de courses a été conçu pour vous offrir commodité et tranquillité d'esprit en prenant en charge vos achats et en les livrant directement à votre porte. Que vous ayez besoin de produits pharmaceutiques, d'épicerie ou d'autres articles essentiels, notre équipe est là pour vous aider à les obtenir sans tracas.
            Lorsque vous utilisez notre service, vous pouvez simplement nous fournir une liste des produits dont vous avez besoin. Nous nous occupons ensuite d'acheter ces articles pour vous, en veillant à sélectionner des produits de qualité et en respectant vos préférences spécifiques. Que ce soit pour des médicaments, des produits d'épicerie ou d'autres articles, nous sommes là pour répondre à vos besoins.
          </Typography>
        </Box>*/}
        
        <Divider sx={{ marginY: 4 }} />
        
        <Box>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <img src={stockMarch} alt="Entrepôt de stockage de marchandises" style={{ width: '100%' }} />
          </Box>
          <Typography variant="h6" gutterBottom fontSize={20} fontWeight='bold'>
            Entrepôt de stockage de marchandises
          </Typography>
          <Typography variant="body1" paragraph>
            Notre entrepôt de stockage de marchandises offre un espace spacieux et sécurisé pour entreposer vos produits. Que vous ayez besoin d'un lieu pour stocker vos marchandises à court terme ou à long terme, notre entrepôt est équipé pour répondre à vos besoins de manière fiable.
            Lorsque vous choisissez notre entrepôt de stockage, vous pouvez avoir l'esprit tranquille en sachant que vos marchandises sont entre de bonnes mains. Nous mettons en place des mesures de sécurité strictes, telles que des systèmes de surveillance et des contrôles d'accès, pour garantir la sécurité de vos produits. De plus, notre équipe expérimentée assure une gestion rigoureuse de l'entrepôt, en veillant à ce que vos marchandises soient organisées et manipulées avec soin.
            Que vous soyez ici ou à l’extérieur, avec nos services de distribution, nous nous occupons de l'acheminement efficace de vos marchandises vers leurs destinations finales.
          </Typography>
        </Box>
      </Box>
      
      <Divider sx={{ marginY: 4 }} />
      
    </Box>
  );
};

export default ServiceScreen;

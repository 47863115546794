import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Termes et Conditions de la Société Djonan
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />
      
      <Typography variant="h5" gutterBottom>
        Conditions générales d’utilisation
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Informations sur Djonan
      </Typography>
      <Typography variant="body1" paragraph>
        Djonan est une société constituée et enregistrée au Burkina Faso, dont le siège social est situé à 1200 Logement, non loin du Lycée Réveil, Ouagadougou. Nos services incluent la livraison de colis et de repas, le transport de personnes en moto ou en taxi, et le transport de bagages par tricycle. Vous pouvez nous contacter à l’adresse email info@djonan.net ou par téléphone au +226 05 76 90 90 ou 58 05 20 20.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Objectif
      </Typography>
      <Typography variant="body1" paragraph>
        Notre objectif est de vous offrir des services de livraison de colis et de repas, de transport de personnes et de bagages, via notre application mobile. Nos services sont disponibles uniquement à Ouagadougou, Burkina Faso.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Votre compte
      </Typography>
      <Typography variant="body1" paragraph>
        Avant de pouvoir solliciter nos services, vous devez créer un compte Djonan. Vous devez garder votre mot de passe ou toute autre méthode de connexion sécurisée (comme Facebook, Google, etc.) confidentiels. Vous êtes responsable de toutes les activités menées sous votre compte, sauf si l’accès non autorisé est dû à une défaillance de notre part. Vous pouvez fermer votre compte à tout moment en nous contactant. Nous nous réservons le droit de suspendre ou de fermer votre compte si nous soupçonnons un usage abusif.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Disponibilité des services
      </Typography>
      <Typography variant="body1" paragraph>
        La disponibilité de nos services dépend de divers facteurs tels que les conditions météorologiques, la demande, et les heures d’ouverture de nos partenaires. Nous vous informerons si un service n’est pas disponible dans votre zone ou à l’heure souhaitée.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Commandes
      </Typography>
      <Typography variant="body1" paragraph>
        Les commandes effectuées via notre plateforme doivent être acceptées par nous ou par le partenaire pour être confirmées. Vous recevrez une notification de confirmation. Vous êtes responsable du paiement de tous les services sollicités via votre compte, y compris les frais de livraison. Certaines commandes peuvent être soumises à une valeur minimale, qui sera affichée sur notre application.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Livraison
      </Typography>
      <Typography variant="body1" paragraph>
        Pour une livraison immédiate, nous vous fournirons un délai de livraison estimé. Des facteurs externes comme les conditions météorologiques et la circulation peuvent causer des retards. Vous serez informé si votre commande a plus de 20 minutes de retard. En cas d'erreur de livraison de votre part (mauvaise adresse, absence au lieu de rendez-vous, etc.), vous serez toujours facturé pour le produit et la livraison.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Vos droits en cas de problème
      </Typography>
      <Typography variant="body1" paragraph>
        Vous avez le droit de recevoir des produits conformes à leur description et de qualité satisfaisante. Si vous pensez que les produits livrés ne respectent pas ces critères, veuillez nous en informer. Nous pourrions demander des preuves photographiques du problème. Nous procéderons à un remboursement ou à un crédit de compte si le problème est confirmé.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Annulation
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez annuler une commande sans frais avant que le partenaire ait commencé à la préparer. Si la préparation a déjà commencé, vous serez facturé le plein prix du produit et, si le livreur a été envoyé, les frais de livraison. Nous pouvons annuler une commande à tout moment. Vous ne serez pas facturé pour les commandes annulées par nous ou par le partenaire.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Prix, paiement et offres
      </Typography>
      <Typography variant="body1" paragraph>
        Les prix des produits et des livraisons peuvent changer. Vous serez informé de tous les frais applicables avant de valider votre commande. Les paiements peuvent être effectués via notre plateforme ou à la livraison en espèces. Les partenaires peuvent offrir des promotions via notre application à leur discrétion.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Protection des données
      </Typography>
      <Typography variant="body1" paragraph>
        Nous traitons vos données personnelles conformément à notre politique de confidentialité disponible sur notre site web.
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Autres conditions
      </Typography>
      <Typography variant="body1" paragraph>
        Nos conditions générales peuvent être modifiées de temps en temps. Les modifications n'affecteront pas les commandes confirmées avant les changements. Ces conditions sont régies par le droit burkinabè et tout litige relatif à notre service peut être porté devant les tribunaux du Burkina Faso.
      </Typography>
    </Box>
  );
}

export default TermsAndConditions;

import TermsAndConditions from "./scenes/term";
import AboutPage from "./scenes/aboutPage";
import Form from "./scenes/contact";
import Footer from "./scenes/global/Footer";
import Topbar from "./scenes/global/Topbar";
import Home from "./scenes/home";
import PrivacyPolicy from "./scenes/privacyPolicy";
import ServiceScreen from "./scenes/serviceScreen";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";


function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />}  />
            <Route path="/contact" element={<Form />}  />
            <Route path="/service" element={<ServiceScreen />}  />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}  />
            <Route path="/term" element={<TermsAndConditions />}  />
          </Routes>
          <Footer />
        </main>
      </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

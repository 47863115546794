// AboutPage.jsx
import React from "react";
import {
  Box,
  Container,
  Typography,
  Avatar,
  Link,
  Button,
  Grid,
} from "@mui/material";
import NosValeur from "../../image/NosValeur.jpg"; // Assurez-vous de remplacer ce chemin par le chemin réel de votre image
import aboutHeader from "../../image/aboutHeader.jpg"; // Assurez-vous de remplacer ce chemin par le chemin réel de l'image de votre PDG
//import sustainabilityImage from './path-to-your-sustainability-image.jpg'; // Assurez-vous de remplacer ce chemin par le chemin réel de l'image de développement durable
//import leadershipImage from './path-to-your-leadership-image.jpg'; // Assurez-vous de remplacer ce chemin par le chemin réel de l'image de leadership
//import diversityImage from './path-to-your-diversity-image.jpg'; // Assurez-vous de remplacer ce chemin par le chemin réel de l'image de diversité
//import integrityImage from './path-to-your-integrity-image.jpg'; // Assurez-vous de remplacer ce chemin par le chemin réel de l'image d'intégrité

const AboutPage = () => {
  return (
    <Container maxWidth="full">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
          mb: 4,
        }}
      >
        <Avatar
          src={aboutHeader}
          alt="about image"
          sx={{ width: "100%", height: "auto", mb: 2 }}
          variant="square"
        />
        <Typography variant="h4" component="h1" gutterBottom fontWeight='bold'>
          À propos
        </Typography>
        <Typography variant="body1" paragraph align="center" fontWeight='bold'>
          Nous repensons la façon dont le monde se déplace pour le mieux, que ce
          soit pour le transport de personnes ou de colis.
        </Typography>
        <Typography variant="body1" paragraph>
          Le mouvement est au cœur de nos activités. C’est notre raison d’être.
          C’est ce qui nous anime. C’est ce qui nous donne envie de nous lever
          chaque matin. C’est cette idée qui nous pousse à réinventer
          constamment les déplacements. Pour vous. DJONAN est une entreprise
          professionnelle de Transport et de Livraison. Notre entreprise de
          transport offre des services fiables destinés aux entreprises et
          particuliers. Vous avez besoin d'envoyer rapidement un paquet, de la
          marchandise, un courrier, un repas... et vous recherchez un partenaire
          de confiance ? La société Djonan vous propose le transport en véhicule
          ou à moto de votre colis, depuis le lieu que vous nous indiquez vers
          la destina tion de votre choix . Nous venons chercher votre
          marchandise et nousla livrons là où vous le souhaitez, aussi
          rapidement que possible.
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom fontWeight='bold'>
          Mission de Djonan Livraison
        </Typography>
        <Typography variant="body1" paragraph>
          Chez Djonan Livraison, notre mission est de révolutionner la manière
          dont les personnes et les colis se déplacent. Nous nous engageons à
          offrir un service de transport fiable, rapide et sécurisé, que ce soit
          à moto ou en voiture. En repensant les solutions de mobilité, nous
          visons à améliorer le quotidien de nos clients en leur offrant des
          options de livraison et de transport efficaces, flexibles et adaptées
          à leurs besoins. Nous sommes déterminés à innover constamment pour
          créer des expériences de déplacement optimales et durables pour tous.
        </Typography>
      </Box>

      {/* Section Lettre de notre PDG */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          mt: 4,
          mb: 4,
        }}
      >
        <Avatar
          src={NosValeur}
          alt="CEO"
          sx={{
            width: { xs: "100%", md: "50%" },
            height: "auto",
            mb: { xs: 2, md: 0 },
            mr: { md: 2 },
          }}
          variant="square"
        />
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            nos valeur
          </Typography>
          <Typography variant="body1" paragraph>
          • Fiabilité<br />
          • Ponctualité<br />
          • Professionnalisme<br />
          • Service client de qualité<br />
          • Sécurité
        </Typography>
      
        </Box>
      </Box>

      {/* Section Développement durable 
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Avatar
            src={womanAndChild}
            alt="Sustainability"
            sx={{ width: "100%", height: "auto" }}
            variant="square"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Développement durable
          </Typography>
          <Typography variant="body1" paragraph>
            Uber s’engage à devenir d’ici 2040 une plateforme entièrement
            électrique et zéro émission, proposant uniquement des courses à bord
            de véhicules propres, via des options de micro-mobilité ou en
            transports en commun. En qualité de leader mondial des plateformes
            de mobilité, il est de notre devoir d’adopter une approche plus
            radicale face à l’enjeu du changement climatique. Pour cela, nous
            allons offrir aux passagers davantage de possibilités de rouler
            vert, aider les chauffeurs à passer à l’électrique, faire de la
            transparence une priorité et collaborer avec des ONG ainsi qu’avec
            le secteur privé afin d’accélérer la transition vers une énergie
            propre et équitable.
          </Typography>
          <Link href="#" variant="body1">
            En savoir plus
          </Link>
        </Grid>
      </Grid>*/}

      {/* Section Informations sur l'entreprise 
      <Box sx={{ mt: 6 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Informations sur l'entreprise
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Avatar
              src={womanAndChild}
              alt="À la tête d'Uber"
              sx={{ width: "100%", height: "auto", mb: 2 }}
              variant="square"
            />
            <Typography variant="h6" component="h3" gutterBottom>
              À la tête d'Uber
            </Typography>
            <Typography variant="body1" paragraph>
              Nous développons une culture au sein d'Uber qui met l'accent sur
              la prise de décisions justes, tout simplement. Cette approche
              s'applique aux passagers, aux chauffeurs et aux collaborateurs.
              Découvrez l'équipe à la tête de cette initiative.
            </Typography>
            <Link href="#" variant="body1">
              Nos dirigeants
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Avatar
              src={womanAndChild}
              alt="Faire place à la diversité"
              sx={{ width: "100%", height: "auto", mb: 2 }}
              variant="square"
            />
            <Typography variant="h6" component="h3" gutterBottom>
              Faire place à la diversité
            </Typography>
            <Typography variant="body1" paragraph>
              Nous voulons créer un espace de travail où personne ne se sent
              exclu, qui reflète la diversité des villes que nous desservons, où
              chacun peut être lui-même et où l'authenticité est considérée
              comme une force. En créant un environnement propice à
              l'épanouissement des personnes de tous horizons, nous ferons
              d'Uber une entreprise meilleure, pour nos collaborateurs comme
              pour nos clients.
            </Typography>
            <Link href="#" variant="body1">
              En savoir plus sur la diversité
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Avatar
              src={womanAndChild}
              alt="Agir avec intégrité"
              sx={{ width: "100%", height: "auto", mb: 2 }}
              variant="square"
            />
            <Typography variant="h6" component="h3" gutterBottom>
              Agir avec intégrité
            </Typography>
            <Typography variant="body1" paragraph>
              La charte du programme d'éthique et de conformité d'Uber souligne
              notre engagement envers l'intégrité au plus haut niveau de
              l'entreprise. La transparence est essentielle à une culture
              éthique ; nous y parvenons grâce à notre service d'assistance
              téléphonique pour l'intégrité et à une série d'initiatives de
              conformité évolutives et efficaces.
            </Typography>
            <Link href="#" variant="body1">
              En savoir plus
            </Link>
          </Grid>
        </Grid>*/}
        {/* Section Informations sur l'entreprise 
        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Informations sur l'entreprise
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Avatar
                src={womanAndChild}
                alt="À la tête d'Uber"
                sx={{ width: "100%", height: "auto", mb: 2 }}
                variant="square"
              />
              <Typography variant="h6" component="h3" gutterBottom>
                À la tête d'Uber
              </Typography>
              <Typography variant="body1" paragraph>
                Nous développons une culture au sein d'Uber qui met l'accent sur
                la prise de décisions justes, tout simplement. Cette approche
                s'applique aux passagers, aux chauffeurs et aux collaborateurs.
                Découvrez l'équipe à la tête de cette initiative.
              </Typography>
              <Link href="#" variant="body1">
                Nos dirigeants
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Avatar
                src={womanAndChild}
                alt="Faire place à la diversité"
                sx={{ width: "100%", height: "auto", mb: 2 }}
                variant="square"
              />
              <Typography variant="h6" component="h3" gutterBottom>
                Faire place à la diversité
              </Typography>
              <Typography variant="body1" paragraph>
                Nous voulons créer un espace de travail où personne ne se sent
                exclu, qui reflète la diversité des villes que nous desservons,
                où chacun peut être lui-même et où l'authenticité est considérée
                comme une force. En créant un environnement propice à
                l'épanouissement des personnes de tous horizons, nous ferons
                d'Uber une entreprise meilleure, pour nos collaborateurs comme
                pour nos clients.
              </Typography>
              <Link href="#" variant="body1">
                En savoir plus sur la diversité
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Avatar
                src={womanAndChild}
                alt="Agir avec intégrité"
                sx={{ width: "100%", height: "auto", mb: 2 }}
                variant="square"
              />
              <Typography variant="h6" component="h3" gutterBottom>
                Agir avec intégrité
              </Typography>
              <Typography variant="body1" paragraph>
                La charte du programme d'éthique et de conformité d'Uber
                souligne notre engagement envers l'intégrité au plus haut niveau
                de l'entreprise. La transparence est essentielle à une culture
                éthique ; nous y parvenons grâce à notre service d'assistance
                téléphonique pour l'intégrité et à une série d'initiatives de
                conformité évolutives et efficaces.
              </Typography>
              <Link href="#" variant="body1">
                En savoir plus
              </Link>
            </Grid>
          </Grid>
        </Box>*/}

        {/* Section Suivez les dernières actualités 
        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Suivez les dernières actualités
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h3" gutterBottom>
                Espace presse
              </Typography>
              <Typography variant="body1" paragraph>
                Recevez des notifications concernant nos partenariats, les mises
                à jour de nos applications, nos initiatives et d'autres
                actualités à proximité et dans le monde entier.
              </Typography>
              <Link href="#" variant="body1">
                Accéder à l'espace presse
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h3" gutterBottom>
                Blog
              </Typography>
              <Typography variant="body1" paragraph>
                Découvrez de nouveaux lieux à explorer et obtenez davantage
                d'informations sur les produits Uber, nos partenariats et plus
                encore.
              </Typography>
              <Link href="#" variant="body1">
                Consultez nos articles
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h3" gutterBottom>
                Relations avec les investisseurs
              </Typography>
              <Typography variant="body1" paragraph>
                Téléchargez nos rapports financiers, consultez nos prévisions
                pour les prochains trimestres et découvrez nos initiatives
                concernant la responsabilité d'entreprise.
              </Typography>
              <Link href="#" variant="body1">
                En savoir plus
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>*/}
      {/* Section Réinventez le monde avec nous 
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 6,
          mb: 6,
          py: 4,
          bgcolor: "background.paper",
        }}
      >
        <Avatar
          src={womanAndChild}
          alt="World"
          sx={{ width: "100px", height: "auto", mb: 2 }}
          variant="square"
        />
        <Typography variant="h4" component="h2" gutterBottom>
          Réinventez le monde avec nous
        </Typography>
        <Button variant="contained" color="primary" href="#">
          Parcourez les offres
        </Button>
      </Box>*/}
    </Container>
  );
};

export default AboutPage;
